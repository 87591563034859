.data-tables {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 10px;
  }
  
  .tabs button {
    padding: 5px 10px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .tabs button.active {
    background-color: #007bff;
    color: white;
  }
  
  .table-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .table-container > table {
    width: 100%;
    table-layout: fixed;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-body-container {
    flex: 1;
    overflow-y: auto;
  }
  
  .table-body-container > table {
    width: 100%;
    table-layout: fixed;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    z-index: 1;
  }
  
  th input {
    width: 100%;
    padding: 5px;
    margin-bottom: 5px;
    box-sizing: border-box;
  }