.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 0; /* Add this line */
}

.header {
  padding: 5px;
}

.dashboard-container {
  flex: 1;
  display: flex;
  min-height: 0; /* Add this line */
}

.connectivity-dashboard-container {
  display: flex;
  width: 100%;
  min-height: 0; /* Add this line */
}

.sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  /* height: 80vh; */
  margin: 5px;
  overflow-y: auto;
  flex-shrink: 0; /* Add this line */
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 5px;
  min-height: 0; /* Add this line */
  overflow-y: auto;
  height: calc(100% - 10px); /* Subtract the total vertical margin */
  /* height: 100vh; */
}

.graph-container {
  flex: 2;
  padding: 10px;
  margin-bottom: 5px;
  overflow: auto;
  border: 2px solid #ccc;
  border-radius: 8px;
  /* height: 50%; */
  min-height: 0; /* Add this line */
}

.data-tables-container {
  flex: 1;
  padding: 10px;
  overflow: auto;
  border: 2px solid #ccc;
  border-radius: 8px;
  /* height: 30%; */
  min-height: 0; /* Add this line */
}

.control-group {
  margin-bottom: 20px;
}

.control-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.control-group input,
.control-group select {
  width: 100%;
  padding: 5px;
}

